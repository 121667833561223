var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "accessories" },
    [
      _c("app-navigation", {
        attrs: {
          title: _vm.$t("accessory.accessories"),
          actionButtonIcon: "fa fa-plus",
          actionButtonDesc: _vm.$t("accessory.new-accessory-add")
        },
        on: {
          action: function($event) {
            return _vm.$router.push({ name: "AccessoryAdd" })
          }
        }
      }),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: {
            data: _vm.filteredAccessories.slice(
              (_vm.currentPage - 1) * _vm.pageSize,
              (_vm.currentPage - 1) * _vm.pageSize + _vm.pageSize
            ),
            "header-row-class-name": "table-header",
            "row-class-name": "table-rows"
          },
          on: { "filter-change": _vm.changeFilter }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "icon",
              label: _vm.$t("accessory.icon"),
              "default-sort": { prop: "name", order: "ascending" },
              width: "80"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("img", {
                      staticClass: "icon-contact",
                      attrs: {
                        src: "/assets/icons/" + scope.row.icon + ".png"
                      },
                      on: { error: _vm.onImageError }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "name",
              label: _vm.$t("accessory.name"),
              sortable: ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "category",
              label: _vm.$t("accessory.category"),
              filters: _vm.categoryList,
              "filter-method": _vm.filterCategory,
              "filtered-value": _vm.filters.category,
              "column-key": "category"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t(_vm.categoryName(scope.row.category))) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "roomId",
              label: _vm.$t("accessory.room"),
              filters: _vm.roomList,
              "filter-method": _vm.filterRoom,
              "filtered-value": _vm.filters.room,
              "column-key": "room"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.roomName(scope.row.roomId)) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "driverId",
              label: _vm.$t("accessory.driver"),
              filters: _vm.driverList,
              "filter-method": _vm.filterDriver,
              "filtered-value": _vm.filters.driver,
              "column-key": "driver"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.driverName(scope.row.driverId)) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "favorite",
              label: _vm.$t("accessory.favorite"),
              width: "120"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: { width: "100%", "text-align": "center" }
                      },
                      [
                        _c("i", {
                          class: scope.row.favorite
                            ? "fa fa-star"
                            : "far fa-star",
                          style: {
                            color: scope.row.favorite ? "#FFC107" : "#9e9e9e"
                          },
                          on: {
                            click: function($event) {
                              return _vm.switchFavorite(scope.row)
                            }
                          }
                        })
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "right", label: _vm.$t("accessory.actions") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      {
                        on: {
                          click: function($event) {
                            return _vm.$router.push({
                              name: "AccessoryEdit",
                              params: { id: scope.row.id }
                            })
                          }
                        }
                      },
                      [_c("i", { staticClass: "far fa-edit" })]
                    ),
                    _c(
                      "span",
                      {
                        on: {
                          click: function($event) {
                            return _vm.dublicateAccessory(scope.row)
                          }
                        }
                      },
                      [_c("i", { staticClass: "far fa-copy" })]
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isRemovableAccessory(scope.row.driverId),
                            expression:
                              "isRemovableAccessory(scope.row.driverId)"
                          }
                        ],
                        on: {
                          click: function($event) {
                            return _vm.removeAccessory(scope.row)
                          }
                        }
                      },
                      [_c("i", { staticClass: "far fa-trash-alt" })]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center", "margin-top": "10px" },
        attrs: {
          "page-sizes": [25, 50, 100, 200, 500],
          "current-page": _vm.currentPage,
          background: "",
          layout: "prev, pager, next, sizes",
          total: _vm.filteredAccessories.length,
          "page-size": _vm.pageSize
        },
        on: {
          "update:currentPage": function($event) {
            _vm.currentPage = $event
          },
          "update:current-page": function($event) {
            _vm.currentPage = $event
          },
          "size-change": function($event) {
            _vm.pageSize = $event
          }
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("accessory.accessory-name"),
            visible: _vm.dublicatePopupVisible
          },
          on: {
            "update:visible": function($event) {
              _vm.dublicatePopupVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { ref: "dublicateForm", attrs: { model: _vm.dublicatedAccessory } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "name",
                    label: _vm.$t("accessory.name"),
                    "label-width": "100",
                    rules: [
                      {
                        required: true,
                        message: _vm.$t("accessory.form-rules.type-name")
                      }
                    ]
                  }
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.dublicatedAccessory.name,
                      callback: function($$v) {
                        _vm.$set(_vm.dublicatedAccessory, "name", $$v)
                      },
                      expression: "dublicatedAccessory.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("accessory.count"),
                    "label-width": "100"
                  }
                },
                [
                  _c("el-input", {
                    attrs: { type: "number", min: "1" },
                    model: {
                      value: _vm.dublicateCount,
                      callback: function($$v) {
                        _vm.dublicateCount = $$v
                      },
                      expression: "dublicateCount"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dublicatePopupVisible = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("accessory.cancel")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmDublicate }
                },
                [_vm._v(_vm._s(_vm.$t("accessory.confirm")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="accessories">
    <app-navigation :title="$t('accessory.accessories')" actionButtonIcon="fa fa-plus" :actionButtonDesc="$t('accessory.new-accessory-add')" @action="$router.push({name: 'AccessoryAdd'})"></app-navigation>

    <el-table @filter-change="changeFilter" 
              :data="filteredAccessories.slice((currentPage - 1) * pageSize, (currentPage - 1) * pageSize + pageSize)"
               v-loading="loading"
               header-row-class-name="table-header"
               row-class-name="table-rows"
              >

      <el-table-column
              prop="icon"
              :label="$t('accessory.icon')"
              :default-sort = "{prop: 'name', order: 'ascending'}"
              width="80">

        <template slot-scope="scope">
          <img @error="onImageError" :src="'/assets/icons/' + scope.row.icon + '.png'" class="icon-contact">
        </template>

      </el-table-column>

      <!--<el-table-column-->
              <!--prop="id"-->
              <!--:label="$t('accessory.id')"-->
              <!--sortable>-->
      <!--</el-table-column>-->

      <el-table-column
              prop="name"
              :label="$t('accessory.name')"
              sortable>
      </el-table-column>

      <el-table-column
              prop="category"
              :label="$t('accessory.category')"
              :filters="categoryList"
              :filter-method="filterCategory"
              :filtered-value="filters.category"
              column-key="category">
        <template slot-scope="scope">
          {{ $t(categoryName(scope.row.category)) }}
        </template>
      </el-table-column>

      <el-table-column
              prop="roomId"
              :label="$t('accessory.room')"
              :filters="roomList"
              :filter-method="filterRoom"
              :filtered-value="filters.room"
              column-key="room">
        <template slot-scope="scope">
          {{ roomName(scope.row.roomId) }}
        </template>
      </el-table-column>

      <el-table-column
              prop="driverId"
              :label="$t('accessory.driver')"
              :filters="driverList"
              :filter-method="filterDriver"
              :filtered-value="filters.driver"
              column-key="driver">
        <template slot-scope="scope">
          {{ driverName(scope.row.driverId) }}
        </template>
      </el-table-column>

      <el-table-column
              prop="favorite"
              :label="$t('accessory.favorite')"
              width="120">
        <template slot-scope="scope">
          <div style="width: 100%; text-align: center">
            <i :class="scope.row.favorite ? 'fa fa-star' : 'far fa-star'"  :style="{color: scope.row.favorite ? '#FFC107' : '#9e9e9e'}" @click="switchFavorite(scope.row)"></i>
          </div>
        </template>
      </el-table-column>

      <el-table-column
              align="right"
              :label="$t('accessory.actions')">

        <template slot-scope="scope">
          <span @click="$router.push({name: 'AccessoryEdit', params: {id: scope.row.id}})"><i class="far fa-edit"></i></span>
          <span @click="dublicateAccessory(scope.row)"><i class="far fa-copy"></i></span>
          <span v-show="isRemovableAccessory(scope.row.driverId)" @click="removeAccessory(scope.row)"><i class="far fa-trash-alt"></i></span>
        </template>

      </el-table-column>

    </el-table>


    <el-pagination style="text-align: center; margin-top: 10px"
                   :page-sizes="[25, 50, 100, 200, 500]"
                   :current-page.sync="currentPage" background
                   layout="prev, pager, next, sizes"
                   :total="filteredAccessories.length"
                   :page-size="pageSize" @size-change="pageSize = $event">

    </el-pagination>



    <el-dialog :title="$t('accessory.accessory-name')" :visible.sync="dublicatePopupVisible">
      <el-form ref="dublicateForm" :model="dublicatedAccessory">
        <el-form-item prop="name" :label="$t('accessory.name')" label-width="100" :rules="[{required: true, message: $t('accessory.form-rules.type-name')}]">
          <el-input v-model="dublicatedAccessory.name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('accessory.count')" label-width="100">
          <el-input type="number" v-model="dublicateCount" min="1"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="dublicatePopupVisible = false">{{$t('accessory.cancel')}}</el-button>
        <el-button type="primary" @click="confirmDublicate">{{$t('accessory.confirm')}}</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import {
  categoryName,
  className,
  categoryNames,
} from "@/utils/accessory-utils";
import { driverTitle, DRIVER_TYPE_CORECENTER } from "@/utils/driver-utils";
import { initializeWebSocket } from "@/utils/socket-utils"
import {
  getFiltersFromLocalStorage,
  setFiltersToLocalStorage,
  removeFiltersFromLocalStorage,
} from '@/api/accessory'

export default {
  name: "Accessories",

  components: {
    "app-navigation": Navigation,
  },

  beforeRouteLeave(to, from, next) {
    if (to.name === "AccessoryEdit") {
      setFiltersToLocalStorage(this.filters);
      return next()
    }
    removeFiltersFromLocalStorage()
    return next()
  },

  data: function() {
    return {
      loading: true,
      categoryNames,
      dublicatePopupVisible: false,
      dublicatedAccessory: {},
      dublicateCount: 1,
      currentPage: 1,
      pageSize: 25,
      filters: {
        category: [],
        room: [],
        driver: [],
      },
      accessories: {}
    };
  },

  computed: {
    categoryList: function() {
      return this.categoryNames.map((category) => {
        return { text: this.$t(category.title), value: category.value };
      });
    },
    roomList: function() {
      return this.$store.getters.rooms.map((room) => {
        return { text: room.name, value: room.id };
      });
    },
    driverList: function() {
      return this.$store.getters.drivers.map((driver) => {
        return { text: driver.driverType, value: driver.id };
      });
    },
    filteredAccessories: function() {
      let accessories = this.$store.getters.accessories;
      if (this.filters.category.length > 0) {
        accessories = this.$store.getters.accessories.filter((accessory) =>
          this.filters.category.includes(accessory.category)
        );
      }

      if (this.filters.room.length > 0) {
        accessories = accessories.filter((accessory) =>
          this.filters.room.includes(accessory.roomId)
        );
      }

      if (this.filters.driver.length > 0) {
        accessories = accessories.filter((accessory) =>
          this.filters.driver.includes(accessory.driverId)
        );
      }
      return accessories;
    },
    isConnected() {
      return this.$store.getters.isConnected;
    },
  },

  watch: {
    isConnected(value) {
      if (value) {
        this.fetchData();
      }
    }
  },

  methods: {
    categoryName,
    className,

    changeFilter(filters) {
      this.filters = { ...this.filters, ...filters };
      console.log("changeFilter", filters, this.filters);
    },
    filterCategory(value, row) {
      return row.category == value;
    },

    filterRoom(value, row) {
      return row.roomId == value;
    },
    filterDriver(value, row) {
      return row.driverId == value;
    },

    onImageError: function(e) {
      e.target.src = "/assets/icons/ic_switch_onoff.png";
    },

    driverName(driverId) {
      return driverTitle(
        this.$store.getters.drivers.find((driver) => driver.id == driverId)
          .driverType
      );
    },

    roomName(roomId) {
      const room = this.$store.getters.rooms.find((room) => room.id == roomId);
      return room ? room.name : roomId;
    },

    switchFavorite(accessory) {
      accessory.favorite = !accessory.favorite;

      const data = {
        type: this.className(accessory.category),
        properties: accessory,
      };

      this.$store.dispatch("editAccessory", data);
    },

    isRemovableAccessory(driverId) {
      return (
        this.$store.getters.drivers.find((driver) => driver.id == driverId)
          .driverType != DRIVER_TYPE_CORECENTER
      );
    },

    dublicateAccessory(accessory) {
      this.dublicatePopupVisible = true;
      this.dublicatedAccessory = { ...accessory };

      console.log("dublicateAccessory", accessory);
    },

    async confirmDublicate() {
      this.$refs.dublicateForm.validate(async (valid) => {
        if (valid && this.dublicateCount > 0) {
          this.dublicatePopupVisible = false;

          const data = {
            type: this.className(this.dublicatedAccessory.category),
            properties: this.dublicatedAccessory,
          };

          console.log("confirmDublicate", data);

          for (var i = 0; i < this.dublicateCount; i++) {
            await this.$store.dispatch("addAccessory", data);
          }

          this.$message({
            message: this.$t("accessory.new-accessories-added"),
            type: "success",
          });
          await this.$store.dispatch("getAccessories");
        }
      });
    },

    removeAccessory(accessory) {
      this.$confirm(
        this.$t("alert.message.confirm-delete"),
        this.$t("alert.title.warning"),
        {
          confirmButtonText: this.$t("alert.button.ok"),
          cancelButtonText: this.$t("alert.button.cancel"),
          type: "warning",
        }
      ).then(() => {
        this.$store
          .dispatch("removeAccessory", accessory.id)
          .then((response) => {
            this.$message({
              message: this.$t(response.message),
              type: "success",
            });
          })
          .catch((reason) => {
            this.$message({
              message: this.$t(reason),
              type: "error",
            });
          });
      });
    },

    async fetchData() {
    Promise.all([
    this.$store.dispatch("getDrivers"),
    this.$store.dispatch("getAccessories"),
    this.$store.dispatch("getRooms"),
  ])
    .then((results) => {
      // results[0] contains the result of getDrivers
      // results[1] contains the result of getAccessories
      this.loading = false;
      this.$nextTick(() => {
        if (getFiltersFromLocalStorage()) {
          this.changeFilter(getFiltersFromLocalStorage());
        }
      });
    })
    .catch((reason) => {
      this.loading = false;
    });
    }
  },

  created() {
    if (!this.isConnected){
      initializeWebSocket()
    }
    this.fetchData()
}
};
</script>

<style scoped>
  @import "../../style/accessories.css";
span i {
  font-size: large;
  padding: 10px;
  cursor: pointer;
}

span i:hover {
  background-color: #00BFD7;
  border-radius: 5px;
  color: white;
}

span i:active {
  box-shadow: 0px 0px 10px #00BFD7;
  border-radius: 5px;
}

div i {
  font-size: large;
  cursor: pointer;
}

.icon-contact {
  filter: brightness(0.45);
  width: 40px;
  height: 40px;
}
</style>
